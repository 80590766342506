import * as React from "react"
import { Link } from "gatsby"

const Pagination = ({ pageContext }) => {
  console.debug(pageContext)
  const {
    humanPageNumber,
    numberOfPages,
    nextPagePath,
    previousPagePath,
    pagePrefix,
  } = pageContext
  if (numberOfPages > 1) {
    let pagesLinks = []
    for (let i = 1; i < numberOfPages + 1; i++) {
      let to = i === 1 ? `/${pagePrefix}` : `/${pagePrefix}/${i}`
      pagesLinks.push(
        <li className={`page-item ${humanPageNumber === i ? "active" : ""}`}>
          <Link className="page-link rounded-2" to={to}>
            {i}
          </Link>
        </li>
      )
    }
    return (
      <nav>
        <ul className="pagination justify-content-center">
          {previousPagePath !== "" && (
            <li
              className={`page-item ${humanPageNumber === 1 ? "disabled" : ""}`}
            >
              <Link className="page-link rounded-2" to={previousPagePath}>
                Previous
              </Link>
            </li>
          )}
          {pagesLinks.map((pageLink, index) => {
            return pageLink
          })}
          {nextPagePath !== "" && (
            <li
              className={`page-item ${nextPagePath === "" ? "disabled" : ""}`}
            >
              <Link className="page-link rounded-2" to={nextPagePath}>
                Next
              </Link>
            </li>
          )}
        </ul>
      </nav>
    )
  }
  return <></>
}

export default Pagination
