import * as React from "react"
import styled from "styled-components"

const FormattedTitle = ({ children, forceUnderline = false }) => {
  const formatted = children.replace(/\*(.*?)\*/g, '<span class="purple-underline">$1</span>');
  if (formatted.includes('purple-underline')) {
    return (
      <>
        <StyledTitle dangerouslySetInnerHTML={{ __html: formatted }} />
      </>
    )
  }

  // Otherwise return full title with a purple underline.
  if (forceUnderline) {
    return (
      <span className="purple-underline">
        <StyledTitle dangerouslySetInnerHTML={{ __html: formatted }} />
      </span>
    )
  }

  return (
    <StyledTitle dangerouslySetInnerHTML={{ __html: formatted }} />
  )
}

const StyledTitle = styled.span``

export default FormattedTitle
