import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Pagination from "../components/common/Pagination"
import AnimationCircleWhite from "../components/animation/animation-circle-white"
import AnimationCirclePink from "../components/animation/animation-circle-pink"
import AnimationElement1 from "../components/animation/animation-element-1"
import AnimationElement2 from "../components/animation/animation-element-2"
import FormattedTitle from "../components/common/FormattedTitle"

const EventsIndex = props => {
  const { data, pageContext } = props
  return (
    <Layout>
      <h1>
        All <span className="purple-underline">Events</span>
      </h1>
      <>
        {data.drupal.nodeQuery.entities.map((entity, index) => {
          const title = entity?.title || "Unknown"
          const eventDate = new Date(entity?.eventDate * 1000).toLocaleString("en-US", { year: 'numeric', month: 'long', day: 'numeric' });
          const thumbnail = getImage(entity?.thumbnail || {})
          const entityUrl = entity?.entityUrl?.path
          const eventUrl = entity?.eventUrl?.value
          return (
            <div
              className="row position-relative py-4 py-md-4 gy-4 gy-md-0"
              key={index}
              style={{
                zIndex: 1,
              }}
            >
              <div className="col col-12 col-md-6 order-md-0 order-1">
                <h2>
                  <Link to={entityUrl} className="white">
                    <FormattedTitle>{title}</FormattedTitle>
                  </Link>
                </h2>
                <div className="pb-4">{eventDate}</div>
                <div
                  dangerouslySetInnerHTML={{ __html: entity?.body?.processed }}
                />
                {eventUrl && (
                  <div className="py-2">
                    <Link to={eventUrl} className="gold">
                      {eventUrl}
                    </Link>
                  </div>
                )}
              </div>
              <div className="col col-12 col-md-6 order-md-1 order-0">
                {thumbnail && (
                  <div>
                    <Link to={entityUrl}>
                      <GatsbyImage
                        alt={entity?.entityLabel || ""}
                        image={thumbnail}
                        imgClassName="preview"
                        loading="eager"
                        className="rounded-4"
                      />
                    </Link>
                  </div>
                )}
              </div>
            </div>
          )
        })}
        <AnimationCircleWhite
          style={{ top: "10%" }}
          className="animation animation-circle-white-1"
        />
        <AnimationCirclePink
          style={{ top: "80%" }}
          className="animation animation-circle-pink-1"
        />
        <AnimationElement1
          className="animation"
          style={{
            position: "absolute",
            top: "30%",
            left: "-150px",
            width: "60px",
          }}
        />
        <AnimationElement2
          className="animation"
          style={{
            top: "50%",
            position: "absolute",
            right: "-150px",
            transform: "scaleX(-1)",
            width: "120px",
          }}
        />
      </>
      <div className="py-4">
        <Pagination pageContext={{ ...pageContext, pagePrefix: "events" }} />
      </div>
      <div className="py-4" style={{ position: "relative", zIndex: 1 }}>
        <Link to="/" className="white">
          Go back to the homepage
        </Link>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    drupal {
      nodeQuery(
        offset: $skip
        limit: $limit
        filter: {
          conditions: [
            { field: "type", operator: EQUAL, value: "project" }
            { field: "status", operator: EQUAL, value: "1" }
          ]
          conjunction: AND
        }
        sort: { field: "field_event_date", direction: ASC }
      ) {
        entities {
          entityId
          entityLabel
          entityUrl {
            path
          }
          ... on Default_NodeProject {
            body {
              processed
            }
            eventDate: fieldEventDate
            title: entityLabel
            eventUrl: fieldUrlEvent {
              value
            }
            image: fieldSmallCircleEvent {
              url
              id: targetId
            }
            thumbnail: gatsbyImageRectangleThumbnail {
              childImageSharp {
                gatsbyImageData(width: 640, height: 640)
              }
            }
          }
        }
      }
    }
  }
`

export default EventsIndex
